<template>
  <div>
    <v-card :height="400" v-if="!loadingGraph">
      <v-card-text style="height: 150px">
        <apexCharts
          ref="realtimeChart"
          class="apexchart"
          width="100%"
          height="350"
          :options="chartOptions"
          :series="series"
        />
      </v-card-text>
    </v-card>
    <v-card :height="280" v-else>
      <v-card-text>
        <div class="d-flex justify-center align-center h-full">
          <no-data />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import 'dayjs/locale/pt-br';

export default {
  name: 'charBar',

  components: {
    ApexCharts: () => import('vue-apexcharts'),
    NoData: () => import('./NoData.vue'),
  },

  props: {
    graphData: {
      type: Object,
      required: false,
    },
    loadingGraph: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      chartOptions: {
        plotOptions: {
          bar: {
            columnWidth: '20%',
          },
        },
        chart: {
          id: 'graficoBarra',
          type: 'bar',
          defaultLocale: 'pt-br',
          locales: [
            {
              name: 'pt-br',
              options: {
                toolbar: {
                  show: false,
                  exportToSVG: 'Baixar SVG',
                  exportToPNG: 'Baixar PNG',
                  exportToCSV: 'Baixar CSV',
                  menu: 'Menu',
                  selection: 'Selecionar',
                  selectionZoom: 'Selecionar Zoom',
                  zoomIn: 'Aumentar',
                  zoomOut: 'Diminuir',
                  pan: 'Navegação',
                  reset: 'Reiniciar Zoom',
                },
              },
            },
          ],
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            colors: ['#000000'],
          },
        },
        colors: ['#442D96', '#C73C44'],
        tooltip: {
          fixed: {
            enabled: false,
            position: 'bottomRight',
            offsetX: 180,
            offsetY: 180,
          },
        },

        xaxis: {
          categories: [],
          labels: {
            formatter: function (value) {
              return dayjs(value).locale('pt-br').format('MMM YYYY');
            },
          },
        },

        title: {
          text: '',
          align: 'center',
          style: {
            color: '#011640',
          },
        },
        legend: {
          show: true,
          fontSize: 16,
          itemMargin: {
            horizontal: 16,
          },
          markers: {
            offsetX: -6,
            margin: 8,
            width: 12,
            height: 12,
            radius: 80,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '60%',
          },
        },
      },

      series: [
        {
          data: [],
          name: '',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      // dataIndex: 'esocial/esocialCedidos/getDataForGraph',
    }),
    isGraphDisabled() {
      return [undefined, null].includes(this.graphData);
    },
  },

  watch: {
    graphData(val) {
      if (val) this.mountData();
    },
  },

  methods: {
    // mountData() {
    //   this.$refs.realtimeChart.updateSeries(
    //     [
    //       {
    //         data: this.graphData.series.name,
    //       },
    //     ],
    //     false,
    //     true,
    //   );
    // },
    mountData() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.graphData.keys,
            labels: {
              formatter: function (value) {
                return dayjs(value).locale('pt-br').format('MMM YYYY').toLocaleUpperCase();
              },
            },
          },
        },
      };

      this.series = this.graphData.series;
    },
  },
};
</script>
<style scoped>
.switch-tfa >>> .v-input--switch__thumb.primary--text {
  color: white !important;
}

.switch-tfa >>> .v-input--switch__track {
  opacity: 100 !important;
}

.v-input__prepend-outer > label {
  color: var(--v-primary-base);
  width: 172px;
  top: 3px;
}
</style>
