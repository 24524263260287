var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loadingGraph
        ? _c(
            "v-card",
            { attrs: { height: 400 } },
            [
              _c(
                "v-card-text",
                { staticStyle: { height: "150px" } },
                [
                  _c("apexCharts", {
                    ref: "realtimeChart",
                    staticClass: "apexchart",
                    attrs: {
                      width: "100%",
                      height: "350",
                      options: _vm.chartOptions,
                      series: _vm.series,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-card",
            { attrs: { height: 280 } },
            [
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center align-center h-full" },
                  [_c("no-data")],
                  1
                ),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }